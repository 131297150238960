$(document).on('change', '#valtozat_id', function() {
    const variant = $('#valtozat_id');
    const product_id = $(variant).closest('.container').data('product-id');
    const price = $(variant).find('option:selected').data('price');
    const type = $(variant).find('option:selected').text();
    const quantity = $('#mennyiseg').val() || 1;

    $('#ar').text(setThousand(Math.round(price)) + ' Ft');

    const url = WEBURL + 'index.cgi?act=kosarba_rakom&' +
        `termek_id_${ product_id }=${ product_id }` +
        `&mennyiseg_${ product_id }=${quantity}` +
        `&description_${ product_id }=Tipus:%20${ type }` +
        `&lock_type=${ type }` +
        `&ar_${ product_id }=${ price }`;

    $('.kosar-gomb-default').attr('href', url);
});

$(document).on('change', '#mennyiseg', function() {
    const variant = $('#valtozat_id');
    const product_id = $(variant).closest('.container').data('product-id');
    const price = $(variant).find('option:selected').data('price');
    const type = $(variant).find('option:selected').text();
    const quantity = $('#mennyiseg').val() || 1;

    const url = WEBURL + 'index.cgi?act=kosarba_rakom&' +
        `termek_id_${ product_id }=${ product_id }` +
        `&mennyiseg_${ product_id }=${quantity}` +
        `&description_${ product_id }=Tipus:%20${ type }` +
        `&lock_type=${ type }` +
        `&ar_${ product_id }=${ price }`;

    $('.kosar-gomb-default').attr('href', url);
});

$(function() {
    $('#valtozat_id').trigger('change');
});
