$(function() {

    $('td').each(function() {
        let content = $(this).html();

        content = content.replace(/\[sugo\](.*?)\[\/sugo\]/, getTooltipButton('$1'));

        $(this).html(content);

        $('[data-toggle="tooltip"]')
            .on('hover', function() {
                $(this).tooltip('show');
            })
            .on('mouseleave', function() {
                $(this).tooltip('hide');
            });
    });
});

function getTooltipButton(content) {
    const html = '<span class="d-inline-block ml-2" tabindex="0" data-toggle="tooltip" data-placement="top" title="'+ content + '">' +
            '<i class="fa fa-question-circle"></i>' +
        '</span>';

    return html;
}