import imageLoader from './_image-loader';
import imageTooltip from './_image-tooltip';
import card from './_card';
import carousel from './_carousel';
import formSubmit from './_form-submit';
import navbar from './_navbar';
import header from './_header';
import search from './_search';
import download_h2 from './_download_h2';
import basket_button from './_basket_button';
import door_wizzard from './_door-wizzard';
import rename_products_on_list_page from './_rename_products_on_list_page';
import set_thousand from './_set-thousand';
import tooltip from './_tooltip';
import product_page from './_product_page';
import file_upload from './_file-upload';
import countdown_setup from './_countdown';
import basket_page from './_basket_page';

$(function() {
    $('.container-slide').find('.tinymce_img').each(function() {
        $(this).attr('data-toggle', 'tooltip');
    });

    $('.szinek div').find('a').each(function() {
        const title = this.getAttribute('title');
        
        const img = $(this).find('img').removeAttr('data-toggle').unwrap();
        $(img).removeAttr('data-fancybox').removeClass('fancybox_images').wrap(
            '<div class="color-container">'+
                '<div class="color"></div>'+
            '</div>'
        );

        $('<div class="color-title">'+ title +'</div>').appendTo( $(img).parent().parent() );
    });

    $('.color-container').each(function() {
        const img = $(this).find('img');
        if ( $(img).data('highlighted') == '1' ) {
            // console.log('highlighted');
            $('<div class="highlighted">' + $(img).data('highlighted-text') + '</div>').insertBefore(img);
        }
    });

    $('.szinek').find('div:has(".color-container")').css({
        'display': 'flex',
        'flex-direction': 'row',
        'flex-wrap': 'wrap'
    });
    
    // show the first color's products
    $('.color').on('click', function() {
        const img = $(this).find('img');
        // hide all models
        $('[data-class]').attr('hidden', true);

        // show only the clicked colors
        const key = $(img).data('id');
        $('[data-class]').each(function() {
            if ( $(this).data('class') === key ) {
                $(this).find('img').attr('src', $(this).find('img').data('src') );
                // console.log('OK', $(this).find('img').data('src') );
                $(this).removeAttr('hidden');
            }
        });
    });
    
    $('.isnt-clickable').find('.color').unbind('click');

    $('.color').each(function() {
        const url = $(this).find('img').attr('src');
        $(this).css({
            'background-image': 'url(' + url + ')'
        })
    });
    
    // trigger a click on first color
    $('.szinek div').find('.color-container').first().find('img').trigger('click');
	
	$('.termek_box .image-container').wrap('<div style="overflow:hidden;"></div>');

    $('.fancybox_images').fancybox();
	
    $('[data-toggle="tooltip"]').tooltip();
});
