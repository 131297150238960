$(function() {
    $('.navbar-toggler').addClass('border ml-auto pt-2');
    $('.navbar-toggler-icon').addClass('fa fa-bars text-black pt-1');

    $('#nav-basket-button').each(function() {
        let quantity = 0;
        const url = 'index.cgi?act=kosar_mennyiseg';

        $.ajax({
            url: url,
            type: 'get',
            success: function(data) {
                quantity = parseInt(data);

                if (quantity > 0) {
                    $('#nav-basket-button')
                        .find('.badge')
                        .removeClass('d-none')
                        .text(data);
                }
            },
        });
    });
});
