let selected = getDefaultSelectedValues();
let door_wizzard_current_step = 0;
let price = getDefaultPrices();

function getDefaultSelectedValues() {
    return {
        color_name: '',
        color_image: '',
        with_door_leaf: true,
        with_door_frame: true,
        with_latch: true,
        product_id: 0,
        width: null,
        open_direction: '',
        frame_size: null,
        frame_variants: null,
        latch_id: null,
        latch_name: '',
        latch_image: '',
        latch_variants: null,
        lock_type: '',
        lock_image: '',
    };
}

function getDefaultPrices() {
    return {
        door: 0,
        frame: 0,
        latch: 0,
        lock: 0,
    };
}

function wizzardNext() {
    door_wizzard_current_step++;

    wizzardUpdateAll();
}

function wizzardPrev() {
    door_wizzard_current_step--;

    wizzardUpdateAll();
}

function wizzardUpdateAll() {
    updateProgressBar();
    updateButtons();
    actualizeWizzard();
    updateSidebar();
}

function updateProgressBar() {
    const newprogress = door_wizzard_current_step > 0 ? door_wizzard_current_step / 5 * 100 : 1;
    $('#wizzard-progressbar').attr('aria-valuenow', newprogress).css('width', newprogress + '%');
}

function actualizeWizzard() {
    switch (door_wizzard_current_step) {
        case 0:
        case 1:
            $('#door-wizzard-summary').addClass('col-sm-4');
            $('#door-wizzard-step-content').removeClass('d-none');
            $('.progress').removeClass('d-none');
            setDoorSizes();
            break;
        
        case 3:
            setFrameSizes();
            break;

        case 4: 
            setLatches();
            break;

        case 5:
            showLockPrice();
            $('#door-wizzard-summary').addClass('col-sm-4');
            $('#door-wizzard-step-content').removeClass('d-none');
            $('.progress').removeClass('d-none');
            break;

        case 6:
            $('#door-wizzard-summary').removeClass('col-sm-4');
            $('#door-wizzard-step-content').addClass('d-none');
            $('.progress').addClass('d-none');
            break;
    
        default:
            break;
    }

    $('.steps').hide();
    $($('.steps')[door_wizzard_current_step]).show();
}

function setDoorSizes() {
    doorWizzard['sizes'].forEach(sizeObject => {
        if (!!sizeObject[ selected.product_id ]) {
            let html = '';

            sizeObject[ selected.product_id ].forEach(size => {
                html += `<div class="col-12 col-sm-4">` +
                        `<div class="py-5 px-2 m-1 font-weight-bold text-center bg-light clickable width-selector" data-width="${ size }">` +
                            `<p class="small muted-text">V&eacute;gleges falny&iacute;l&aacute;s m&eacute;ret</p>` +
                            `<p>${ size } cm</p>` +
                            `<button class="btn btn-sm btn-secondary">Ezt v&aacute;lasztom</button>` +
                        `</div>` +
                    `</div>`;
            });
            
            $('#width-select-container').html(html);
        }
    });
}

function setFrameSizes() {
    doorWizzard['frame_sizes'].forEach(sizeObject => {
        if (!!sizeObject[ selected.product_id ]) {
            let html = '';

            sizeObject[ selected.product_id ].forEach(size => {
                html += `<div class="col-12 col-sm-4">` +
                    `<div class="py-5 px-2 m-1 font-weight-bold text-center bg-light clickable frame-size-selector" data-frame-size="${ size }">` +
                        `<p class="small muted-text">&Aacute;ll&iacute;that&oacute;s&aacute;gi tartom&aacute;ny</p>` +
                        `<p>${ size } cm</p>` +
                        `<button class="btn btn-sm btn-secondary">Ezt v&aacute;lasztom</button>` +
                    `</div>` +
                `</div>`;
            });
            
            $('#frame-size-container').html(html);
        }
    });
}

function setLatches() {
    let html = '';

    doorWizzard['latches'].forEach(latch => {
        const latch_prices = JSON.parse(latch.price);
        const bb_latch = latch_prices.find(_ => _.name === 'BB');

        html += `<div class="wizzard-latch col-6 col-sm-3 p-4" ` +
                `data-id="${ latch['id'] }" ` +
                `data-name="${ latch['name'] }"` +
                `data-image="${ latch['image'] }"` +
                `data-price="${ JSON.stringify(latch['price']).replace(/\"/g, '&quot;') }"` +
                `>` +
            `<div class="wizzard-latch-image ` +
                (selected.open_direction === 'balos' ? 'flip' : '') +
                `" style="background-image: url('${ latch['image'] }')"></div>` +
            `<div class="wizzard-latch-name">${ latch['name'].replace(/ kilincs/i, '') }</div>` +
            `<div class="text-center small">${ !!bb_latch ? setThousand(Math.round(bb_latch.price_netto)) + ' Ft' : 0 }</div>` +
        `</div>`;
    });

    $('#latch-list').html(html);
}

function showLockPrice() {
    const lock_bodies = JSON.parse(doorWizzard['lock_body'].replace(/\\\"/g, '"').replace(/^"(.*?)"$/, '$1'));
    const lock_wc_din = lock_bodies.find(_ => _.name === selected.open_direction);
    const bb_latch_price = selected.latch_variants.find(_ => _.name === 'BB').price_netto;
    const wc_latch_price = selected.latch_variants.find(_ => _.name === 'WC').price_netto;

    $('#lock-type-bb-price').html( setThousand(Math.round(bb_latch_price)) + ' Ft<br>&nbsp;' );
    $('#lock-type-wc-price').html( setThousand(Math.round(wc_latch_price + lock_wc_din.price_netto)) + ' Ft<br>(Z&aacute;rtesttel egy&uuml;tt)' );
}

function updateButtons() {
    let html = '';

    switch (door_wizzard_current_step) {
        case 0:
            html = '';
            break;

        case 1:
        case 2:
        case 3:
        case 4:
        case 5:            
            html = '<button class="btn btn-secondary" id="wizzard-prev">Vissza</button>' +
                '<button class="btn btn-primary" id="wizzard-next">Tov&aacute;bb</button>';
            break;
        
        case 6:
            html = '<button class="btn btn-secondary" id="wizzard-prev">Vissza</button>' +
                '<button class="btn btn-primary" id="wizzard-finish-and-reset">Kos&aacute;rba &eacute;s &uacute;j ajt&oacute; &ouml;ssze&aacute;ll&iacute;t&aacute;sa</button>' +
                '<button class="btn btn-primary" id="wizzard-finish">Kos&aacute;rba rakom</button>';
            break;
    
        default:
            break;
    }

    $('#door-wizzard-modal-footer').html(html);
}

function updateSidebar() {
    let html = '<span class="text-muted small">M&eacute;g nem v&aacute;lasztott ajt&oacute;t. Kattintson az egyik ajt&oacute; k&eacute;ra.</span>';

    if (!!selected.color_name && !!selected.color_image) {
        let size_class = 'col-sm-6';

        if (door_wizzard_current_step === 6) {
            size_class = 'col-sm-4';
        }

        setFramePrice();        

        html = `<div class="row">` +
            `<div class="col-12 ${size_class} quick-summary">` +
                `<div class="sidebar-selected-image-container">` +
                    `<div class="wizzard-color-image ` +
                        (selected.open_direction === 'balos' ? 'flip' : '') +
                        `" style="background-image: url('${ selected.color_image }');"></div>` +
                    `<div class="wizzard-color-name text-center">${ selected.color_name }</div>` +
                `</div>` +
                (!!selected.latch_id ?
                    '<div class="pt-3 text-center" style="width: 65%; margin: 0 auto;">' +
                        `<div class="wizzard-color-image ` +
                            (selected.open_direction === 'balos' ? 'flip' : '') +
                            `" style="height: 130px; background-image: url('${ selected.latch_image }');"></div>` +
                        `<div class="wizzard-color-name text-center small">${ selected.latch_name }</div>` +
                    `</div>` :
                    ''
                ) +
            `</div>` +
            `<div class="col-12 ${size_class} quick-summary">` +
                
                (selected.width !== null ?
                    '<p><span class="small text-muted">Falny&iacute;l&aacute;s m&eacute;ret:</span><br>' +
                        (!selected.with_door_leaf ?
                            'Ajt&oacute;lap n&eacute;lk&uuml;l' :
                            selected.width ? selected.width + ' cm' : ''
                        ) +
                    '</p>' : '') +
                
                (!!selected.open_direction ?
                    '<p><span class="small text-muted">Nyit&aacute;si ir&aacute;ny:</span><br>' + selected.open_direction + '</p>' :
                    ''
                ) +
                
                (selected.frame_size !== null ?
                    '<p><span class="small text-muted">Tok m&eacute;ret:</span><br>' +
                        (!selected.with_door_frame ?
                            'Tok n&eacute;lk&uuml;l' :
                            selected.frame_size ? selected.frame_size + ' cm' : '') +
                    '</p>' : '') +
                
                (selected.latch_id !== null ?
                    '<p><span class="small text-muted">Kilincs:</span><br>' +
                        (!selected.with_latch ?
                            'Kilincs n&eacute;lk&uuml;l' :
                            selected.latch_name) +
                    '</p>' : '') +
                
                (!!selected.lock_type ?
                    '<p><span class="small text-muted">Z&aacute;r t&iacute;pus:</span><br>' +
                    selected.lock_type +
                    `<img src="${ selected.lock_image }" style="width: 30px; margin-left: 15px" alt="*">` +
                '</p>' : '') +

            `</div>` +

            // arak
            `<div class="col-12 col-sm-4 ${door_wizzard_current_step !== 6 ? 'd-none' : ''}" id="price-summary">` +
                `<h3>&Aacute;rak</h3>` +
                `<div class="row border-bottom pb-3 mb-3"><div class="col-5">Ajt&oacute;lap:</div>` +
                    `<div class="col-7 text-right">${ setThousand(Math.round(price.door)) } Ft</div></div>` +
                `<div class="row border-bottom pb-3 mb-3"><div class="col-5">Tok:</div>` +
                    `<div class="col-7 text-right">${ setThousand(Math.round(price.frame)) } Ft</div></div>` +
                `<div class="row border-bottom pb-3 mb-3"><div class="col-5">Kilincs:</div>` +
                    `<div class="col-7 text-right">${ setThousand(Math.round(price.latch)) } Ft</div></div>` +
                `<div class="row border-bottom pb-3 mb-3"><div class="col-5">Z&aacute;rtest:</div>` +
                    `<div class="col-7 text-right">${ setThousand(Math.round(price.lock)) } Ft</div></div>` +
                `<div class="row border-bottom pb-3 mb-3"><div class="col-5"><strong>&Ouml;sszesen:</strong></div>` +
                    `<div class="col-7 text-right"><strong>${ setThousand(Math.round(getTotalPrice())) } Ft</strong></div></div>` +
            `</div>` +
        `</div>`;
    }

    $('#current-set').html(html);
}

function getTotalPrice() {
    return Object
        .keys(price)
        .reduce((sum, key) => sum + parseFloat(price[key] || 0), 0);
}

function setFramePrice() {
    const csoport = !!selected.width ? selected.width.split('/')[0] : '';
    const name = selected.frame_size + ' ' + selected.open_direction;
    const variant = selected.frame_variants.find(_ => _.csoport === csoport && _.name === name);
    
    price.frame = !!variant ? variant.price_netto : 0;
}

function setDoorColor() {
    // add door images
    let door_images = '';
    doorWizzard['images'].forEach((image) => {
        door_images += `<div class="wizzard-color col-6 col-sm-3 col-xl-2" ` +
                `data-id="${ image['id'] }" ` +
                `data-image="${ image['file'] }" ` +
                `data-price-door="${ !!image['price_door'] ? image['price_door'] : 0 }" ` +
                `data-frame-variants="${ !!image['price_frame'] ? JSON.stringify(image['price_frame']).replace(/\"/g, '&quot;') : 0 }" ` +
            `>` +
                `<div class="wizzard-color-image" style="background-image: url('${ image['file'] }');"></div>` +
                `<div class="wizzard-color-name text-center">${ image['name'].replace(/ DoorOutlet belt.ri ajt./i, '') }</div>` +
                `<div class="text-center small">${ !!image['price_door'] ? setThousand(Math.round(image['price_door'])) + ' Ft' : 0 }</div>` +
            `</div>`;
    });

    door_images += `<div class="green-border small col-12 col-sm-9 col-xl-6 py-3">` +
        `Tisztelt &Eacute;rdekl&#337;d&#337;!<br><br>Az ajt&oacute;rendel&#337; seg&eacute;d programunk ` +
        `csak rakt&aacute;ri term&eacute;kek rendel&eacute;s&eacute;re alkalmas.<br><br>`+
        `Egyedi ig&eacute;nyekkel kapcsolatban <a href="${ WEBURL }kapcsolat" target="_blank">keresse munkat&aacute;rsainkat.</a>` +
        `</div>`;

    $('#color-list').html(door_images);
}

function setLatchPrice() {
    // kilincs nelkul keri
    if (!selected.with_latch) {
        price.latch = 0;

        setLockPrice();

        return;
    }
    
    const latch_variant = selected.latch_variants.find(_ => _.name === selected.lock_type);
    
    price.latch = !!latch_variant ? latch_variant.price_netto : 0;

    setLockPrice();
}

function setLockPrice() {
    // BB zaras kilincs eseten nincs felar a zarra
    if (selected.lock_type === 'BB') {
        price.lock = 0;
        
        return;
    }

    const variants = JSON.parse(doorWizzard['lock_body'].replace(/\\\"/g, '"').replace(/^"(.*?)"$/, '$1'));
    const variant = variants.find(_ => _.name === selected.open_direction);

    price.lock = !!variant ? variant.price_netto : 0;
}

function reset() {
    selected = getDefaultSelectedValues();
    price = getDefaultPrices();
    door_wizzard_current_step = 0;
    
    wizzardUpdateAll();
}

function save(callbackOnSuccess) {
    const url = getBasketUrl();
    console.log('url', url.join('&'));

    $.ajax({
        url: url.join('&'),
        type: 'get',
        success: function() {
            if (callbackOnSuccess !== undefined) {
                callbackOnSuccess();
            }
        },
    });
}

function urlString(product_id, description, ar) {
    if (!product_id) {
        return [];
    }

    const mennyiseg = 1;

    const items = [
        'termek_id_' + product_id + '=' + product_id,
        'mennyiseg_' + product_id + '=' + mennyiseg,
        'description_' + product_id + '=' + encodeURI((description).normalize("NFD").replace(/[\u0300-\u036f]/g, "")),
    ];

    if (!!ar) {
        items.push('ar_' + product_id + '=' + ar);
    }

    return items;
}

function getBasketUrl() {
    console.log(selected);
    const door_id = selected.product_id;
    const is_with_latch = selected.latch_id !== 0 && selected.latch_id !== null;
    
    const door_description = 'Dekor ajtolap ' +
        selected.color_name + ', ' +
        // selected.frame_size + ', ' +
        selected.width + ', ' +
        selected.open_direction + ', ' +
        (is_with_latch ? selected.lock_type + ' zar, ' : '') +
        'krom pant';

    const frame_height = !!selected.width ? selected.width.split('/')[0] : '0';
    const frame_variant = !!selected.frame_variants ? selected.frame_variants.find(_ =>
        _.csoport === frame_height
        && _.name === selected.frame_size + ' ' + selected.open_direction
    ) : 0;
    const frame_description = 'Dekor ajto tok ' +
        selected.color_name + ', ' +
        selected.frame_size + ', ' +
        selected.width + ', ' +
        selected.open_direction;
    const frame_price = price.frame;

    const is_with_wc_lock = selected.lock_type === 'WC';
    const wc_lock_id = 45;
    const wc_lock_description = 'WC zar, ' + selected.open_direction;
    const wc_lock_price = price.lock;
    
    const latch_id = selected.latch_id;
    const latch_description = 'Tipus: ' + selected.lock_type;
    
    // urlString() is in _basket_button.js
    return url = [
        WEBURL + 'index.cgi?act=kosarba_rakom',
        ...(selected.with_door_leaf ? urlString(door_id, door_description) : '1=1'),
        ...(selected.with_door_frame ? urlString(frame_variant.termek_id, frame_description, frame_price) : '1=1'),
        ...(is_with_latch ? urlString(latch_id, latch_description) : '1=1'),
        ...(is_with_wc_lock ? urlString(wc_lock_id, wc_lock_description, wc_lock_price) : '1=1'),
        (is_with_latch ? 'lock_type=' + selected.lock_type : '1=1'),
    ];
}


function saveAndReset() {
    save(function() {
        reset();
    });
}

function saveAndGoToBasket() {
    save(function () {
        window.location.href = WEBURL + 'kosar';
    });
}

$(function () {
    $('.steps').hide();

    $('.step01').show();

    setDoorColor();

    $('#jobbos').on('click', function() {
        selected.open_direction = 'jobbos';
        wizzardNext();
    });

    $('#balos').on('click', function() {
        selected.open_direction = 'balos';
        wizzardNext();
    });

});

$(document).on('click', '.wizzard-color', function() {
    selected.product_id = $(this).data('id');
    selected.color_image = $(this).data('image');
    selected.color_name = $(this).find('.wizzard-color-name').text();
    selected.frame_variants = $(this).data('frame-variants');
    price.door = $(this).data('price-door');
    price.frame = $(this).data('price-frame');

    wizzardNext();
});

$(document).on('click', '.width-selector', function() {
    selected.width = $(this).data('width');

    wizzardNext();
});

$(document).on('click', '.frame-size-selector', function() {
    selected.frame_size = $(this).data('frame-size');

    wizzardNext();
});

$(document).on('click', '.wizzard-latch', function() {
    selected.latch_id = $(this).data('id');
    selected.latch_name =  $(this).data('name');
    selected.latch_image =  $(this).data('image');
    selected.latch_variants = JSON.parse($(this).data('price').replace(/\\\"/g, '"').replace(/^"(.*?)"$/, '$1'));
    
    wizzardNext();
});

$(document).on('change', '#with_door_leaf', function() {
    selected.with_door_leaf = !$(this).is(':checked');

    if (!selected.with_door_leaf) {
        selected.width = '';
        wizzardNext();
    }
});

$(document).on('change', '#with_door_frame', function() {
    selected.with_door_frame = !$(this).is(':checked');

    if (!selected.with_door_frame) {
        selected.frame_size = '';
        price.frame = 0;
        wizzardNext();
    }
});

$(document).on('change', '#with_latch', function() {
    selected.with_latch = !$(this).is(':checked');

    if (!selected.with_latch) {
        selected.latch_id = 0;
        price.latch = 0;
        wizzardNext();
    }
});

$(document).on('click', '#bb', function() {
    selected.lock_type = 'BB';
    
    $(this).find('img').each(function() {
        selected.lock_image = $(this).attr('src');
    });

    setLatchPrice();
    wizzardNext();
});

$(document).on('click', '#wc', function() {
    selected.lock_type = 'WC';

    $(this).find('img').each(function() {
        selected.lock_image = $(this).attr('src');
    });

    setLatchPrice();
    wizzardNext();
});

$(document).on('click', '#wizzard-next', function() {
    wizzardNext();
});

$(document).on('click', '#wizzard-prev', function() {
    wizzardPrev();
});

$(document).on('click', '#wizzard-finish', function() {
    saveAndGoToBasket();
});

$(document).on('click', '#wizzard-finish-and-reset', function() {
    saveAndReset();
});
