$(function(){
    $('.header').each(function(){
        $(this).find('img').first().hide();
        var hpic = $(this).find('img').attr('src');
        $(this).css('background-image', 'url("' + hpic + '")');
        $(this).html('<div class="container-slide"><div class="container h-100"><div class="col-12 slide-padding h-100">' + $(this).html() + '</div></div></div>');
    });
    $('.header .slide-padding p a').each(function(){
		$(this).replaceWith($('' + this.innerHTML + ''));
	});

});
