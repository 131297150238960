let product_ids = [];
const url = window.location.protocol+'//'+window.location.hostname+'/termekeink#';


$(function() {
	// set checkbox & url on checkbox change
    $('.search-item').find('input[type=checkbox]').each(function() {
		$(this).unbind('change').on('change', function() {
			// checkbox ikon megvaltoztatasa
			if ( $(this).is(':checked') ) {
				$(this).closest('.search-item').find('.icon').attr('class', 'icon far fa-check-square');
			} else {
				$(this).closest('.search-item').find('.icon').attr('class', 'icon far fa-square');
			}
			
			setUrl();
			productToggle();
		});
	});

	// set click event and change the checkbox status
	// .color-sample, .icon-container, -- this needs when icons shown
	$('.icon, [class*=-label]').unbind('click').on('click', function() {
		// console.log('clicked');
		$(this).closest('.search-item').find('input[type=checkbox]').each(function() {
			if ( $(this).is(':checked') ) {
				$(this).attr('checked', false).trigger('change');
			} else {
				$(this).attr('checked', true).trigger('change');
			}
		});
	});
})

// set url by selected checkboxes
function setUrl() {
	let selected_type_ids = [];
	let search_ids = [];
	
	// find all selected checkbox and set up id arrays
	$('[class*=search-by-]').find('input[type=checkbox]:checked').each(function() {
		search_ids.push( $(this).closest('.search-item').data('products') );
		selected_type_ids.push( $(this).attr('id').split('_')[1] );
	});

	// set up selected products id array
	product_ids = search_ids.join(',').split(',');

	// search - array of product ids what we are looking for
	// selected_type - array of selected checkboxes id
	const url_extend = 'search='+search_ids.join(',')+
						'&selected_type='+selected_type_ids.join(',');
	
	// set up url field in browser
	window.location.href = url + url_extend;
}

$(document).ready(function() {
	parseUrl();
});

function parseUrl() {
	// split url query into an array
	let url_parts = window.location.toString().split('#');
	let url_params = (url_parts[1] !== undefined ? url_parts[1].split('&') : []);

	// parse the query array
	for (let i = 0; i < url_params.length; i++) {
		if ( url_params[i].indexOf('search') != -1 ) {
			let selected_ids = url_params[i].split('=')[1].split(',');

			// show all products if none selected and exit
			if ( selected_ids[0].length === 0 ) {
				toggleAllProducts('show');
				break;
			}

			// load up the selected products id array and set up checkboxes
			for (let j = 0; j < selected_ids.length; j++) {
				// load up the selected products id to the global array
				product_ids.push( selected_ids[j] );
			}

			// show selected products
			productToggle();
		}
		if ( url_params[i].indexOf('selected_type') != -1 ) {
			let selected_ids = url_params[i].split('=')[1].split(',');
			for (let j = 0; j < selected_ids.length; j++) {
				$( $('.search-item')[ selected_ids[j] ] ).find('input[type=checkbox]').attr('checked', true).trigger('change');
			}
		}
	}
}


function toggleAllProducts(toggle) {
	if ( toggle === 'show' ) {
		$('[data-product-id]').show();
	} else {
		$('[data-product-id]').hide();
	}
}

// toggle selected products
function productToggle() {
	// show all products if nothing selected
	if ( product_ids[0].length === 0 ) {
		toggleAllProducts('show');
		return true;
	}

	// filter if something is selected
	$('[data-category-id="1"]').find('[data-product-id]').hide();
	var objs = $('[data-category-id="1"]').find('.termek_box');
	
	for (var i = 0; i < objs.length; i++) {
		var id = $(objs[i]).data('product-id').toString();

		for (var j = 0; j < product_ids.length; j++) {
			if ( product_ids[j] === id ) {
				$(objs[i]).show()
			}
		}
	}
}
