// Set the date we're counting down to
var countDownDate = new Date("Jul 31, 2021 23:59:59").getTime();

// Get today's date and time
var now = new Date().getTime();

// Find the distance between now and the count down date
var distance = countDownDate - now;

if (distance > 0) {
    // Update the count down every 1 second
    var x = setInterval(function () {
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // find element
        element = document.getElementById("downcounter");

        if (!!element) {
            // Display the result in the element with id="demo"
            element.innerHTML =
                (days ? days + " nap " : '') +
                hours + ":" + minutes + ":" + seconds;

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                element.innerHTML = "EXPIRED";
            }
        }
    }, 1000);
} else {
    var containers = document.querySelectorAll('.hide-on-expire');

    containers.forEach((container) => {
        container.style.display = 'none';
    });

    // find element
    element = document.getElementById("downcounter");

    if (!!element) {
        element.innerHTML = "EXPIRED";
    }
}
