let fileCounter = 0;

function addFileUploadFileds() {
    const files = $('#upload-files');

    if (!files) {
        return;
    }

    const html = '<tr><td colspan="2" class="pt-3">' +
            '<h3>Mell&eacute;kletek</h3>' +
            '<p>Panasza min&eacute;l gyorsabb kivizsg&aacute;l&aacute;sa &eacute;rdek&eacute;ben ' +
            'csatoljon fot&oacute; mell&eacute;kletet is. Ezzel nagyban felgyors&iacute;thatja az ' +
            '&uuml;gyint&eacute;z&eacute;st.</p>' +
        '</td></tr>' +
        '<tr><td colspan="2">' +
            '<button type="button" class="btn btn-secondary" id="add-file-row">&Uacute;j mell&eacute;klet hozz&aacute;ad&aacute;sa</button> (maximum 5 f&aacute;jl)' +
        '</td></tr>';

    files.html(html);

    $(document).on('click', '#add-file-row', function() {
        addFileRow();
    });
}

function addFileRow() {
    ++fileCounter;

    if (fileCounter > 5) {
        return;
    }

    const files = $('#upload-files');

    if (!files) {
        return;
    }

    const html = `<tr><td>Mell&eacute;klet (${ fileCounter })</td><td><input type="file" class="form-control" name="extra,file,${fileCounter}" accept="images/*"></td></tr>`;

    files.html( files.html() + html );
}

$(function() {
    // <tbody id="files"></tbody>
    addFileUploadFileds();
});
