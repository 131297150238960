(function ($) {
    $.fn.imageTooltip = function (options) {

        var defaults = {
            imgHeight: '15em',
            imgWidth: '15em',
            backgroundColor: '#fff'
        };

        if (typeof (options) === 'object') {
            options = $.extend(defaults, options);
        } else {
            var tempOptions = {};
            tempOptions.imgHeight = arguments[0] || defaults.imgHeight;
            tempOptions.imgWidth = arguments[0] || defaults.imgWidth;            
            tempOptions.backgroundColor = arguments[1] || defaults.backgroundColor;
            options = tempOptions;
        }

        function calLeft(x, imgWidth) {
            return window.innerWidth - x < imgWidth ? x : x - imgWidth;
        }

        function calTop(y, imgHeight) {
            return window.innerHeight - y > imgHeight ? y : y - imgHeight;
        }

    
        // let imgContainer;

        // var imgContainer = $('<p>', {
        //     css: {
        //         display: 'none',
        //         backgroundColor: options.backgroundColor,
        //         padding: '5px',
        //         position: 'absolute'
        //     }
        // });

        // var img = $('<img>', {
        //     src: $(this).data('image-tooltip') || $(this).attr('src'),
        //     alt: 'Image Not Available',
        //     width: 500 + 'px', //options.imgWidth,
        //     height: 500 + 'px', //options.imgHeight
        // });

        // imgContainer.append(img);

        $('.colors').hover(
            function (e) {
                let src = $($(this).children('img')[0]).attr('src');

                var imgContainer = $('<p>', {
                    css: {
                        display: 'none',
                        backgroundColor: options.backgroundColor,
                        padding: '5px',
                        position: 'absolute',
                        width: '15em',
                        height: '15em'
                    }
                });
    
                var img = $('<img>', {
                    src: src,
                    alt: 'szin_mintak',
                        //options.imgWidth,
                    //options.imgHeight
                });

                imgContainer.append(img);

                $(this).append(imgContainer);
                imgContainer.show('fast');
                
                imgContainer.css({
                    //left: calLeft(e.clientX, imgContainer.outerWidth()) + 'px',
                    //top: calTop(e.clientY, imgContainer.outerHeight()) + 'px',
                    left: -80 + 'px',
                    bottom: 80 + '%', 
                });
            },
            function () {
                $('.colors').children('p').remove();
                // imgContainer.remove();
            }
        )
        
    };
}(jQuery));