// on change #fizmod set same value for #fizmod2
const fizmod = document.querySelector('#fizmod')

if (!!fizmod) {
    fizmod.addEventListener('change', function() {
        document.querySelector('#fizmod2').value = this.value;
    });
    // trigger change event on #fizmod
    document.querySelector('#fizmod').dispatchEvent(new Event('change'));
}
