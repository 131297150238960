$(function() {
    $('.card').each(function(){
        const container = $(this).find('.image-container');
        const card = $(this).find('.card-title a');
        let str = 'R&eacute;szletek';

        if ( $(this).parent().attr('id') === 'termek_box_18' )
            str = '9 sz&iacute;nben, tele kivitelben rakt&aacute;ron!';
        
            if ( $(this).parent().attr('id') === 'termek_box_15' )
            str = '9 sz&iacute;nben 10-12-14-es falvastags&aacute;ghoz rakt&aacute;ron!';

        container.append('<div class="detail-v2 p-0"><div class="detail-v3"><span><a>' +
                str +
                '</a><span class="arrow-container"></span></span></div></div>');
		const detail = $(this).find('.detail-v3');
        container.hover(
            function () {
                card.addClass('special');
            }, function () {
                card.removeClass('special');
            }
        );
        card.hover(
            function () {
				detail.addClass('transtext');
				container.addClass('trans bcolor');
            }, function () {
				container.removeClass('trans bcolor');
				detail.removeClass('transtext');
            }
        );
    });
});
