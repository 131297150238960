$(function(){
    $('.carousel-item').each(function(){
        $(this).find('p').first().hide();
        var pic = $(this).find('img').attr('src');
        $(this).css('background-image', 'url("' + pic + '")');
        $(this).html(
            '<div class="container-slide d-flex align-items-center">'+
                '<div class="container">'+
                    '<div class="col-12 slide-padding">' +
                        $(this).html() +
                    '</div>'+
                '</div>'+
            '</div>'
        );
    });

    // single image
    $('.single-image-banner').each(function() {
        let img = $(this).find('img').attr('src');
        $(this).find('p').hide();
        $(this).find('.widget_szovegek').css({
            'background-image': 'url(' + img + ')'
        });
    });
});
