const product_price = {
    door: 0,
    frame: 0,
    latch: 0,
    lock: 0,
};

$(function() {
    if (!!$('#datablock')[0]) {
        let color = $('#color_selector').val();
        let model = $('#model_selector').val();

        addSelect('sizes');
        
        addSelect('open-direction');
        
        setWithFrame();
        
        setLatch();
        setWithLatch();
        
        // addSelect('frame-sizes');
        
        addSelect('wall-weight');
        
        
        addSelect('lock-type');
        $(document).on('change', '#lock-type-selector', function() {
            setBasektButtonUrl();
            setLatchPrice();
        });
        $('#lock-type-selector').trigger('change');

        // door price
        const door_price = parseInt($( $('.price')[0] ).text().replace(/ /g, '') );
        product_price.door = door_price;

        setBasektButtonUrl();
        updateProductPriceTotal();
    }

    if (!!$('#mennyiseg')[0]) {
        $(document).on('change keyup', '#mennyiseg', function() {
            setBasektButtonUrl();
        });
    }

});

function setWithFrame() {
    $('#with-frame').append('<input type="checkbox" id="is-with-frame" checked>');

    $(document).on('change', '#is-with-frame', function() {
        $('#frame, #wall').toggleClass('d-none');
        $('.frame-price').closest('tr').toggleClass('d-none');

        setBasektButtonUrl();
        updateProductPriceTotal();
    });

    setBasektButtonUrl();
    updateProductPriceTotal();
}

function setWithLatch() {
    $('#with-latch').append('<input type="checkbox" id="is-with-latch" checked>');

    $(document).on('change', '#is-with-latch', function() {
        $('#row-latch').toggleClass('d-none');
        $('#latch-price').closest('tr').toggleClass('d-none');
        
        setLatchPrice();
        setBasektButtonUrl();
        updateProductPriceTotal();
    });

    setLatchPrice();
    setBasektButtonUrl();
    updateProductPriceTotal();
}

function updateProductPriceTotal() {
    const total_price = getProductPriceTotal();
    $('#price-total').html(`<strong>${ setThousand(Math.round(total_price)) } Ft (brutt&oacute;)</strong>`);
}

function getProductPriceTotal() {
    return Object
        .keys(product_price)
        .reduce((sum, key) => sum + parseFloat(product_price[key] || 0), 0);
}

function addSelect(name) {
    const items = $('#datablock').data('product-' + name).split(';');

    let html = '<select id="' + name + '-selector" class="form-control">';
    items.forEach(item => {
        html += '<option value="' + item + '">' + item + '</option>';
    });
    html += '</select>';

    $('#' + name).append(html);

    $(document).on('change', '#' + name + '-selector', function() {
        setBasektButtonUrl();
        updateProductPriceTotal();
    });
}

function setLatch() {
    const items = $('#datablock').data('product-latches').split(';');
    let html = '<select id="latch-selector" class="form-control">';
    
    items.forEach(item => {
        [id, name, variant_json] = item.split('::');
        html += '<option value="' + id + '" data-variant="' + variant_json + '">' + name + '</option>';
    });
    
    html += '</select>';

    $('#latch').html(html);

    $(document).on('change', '#latch-selector', function() {
        setBasektButtonUrl();
        setLatchPrice();
    });
    $('#latch-selector').trigger('change');
}

function setLatchPrice() {
    const is_with_latch = $('#is-with-latch').is(':checked');
    let variant_json = $('#latch-selector').find('option:selected').data('variant').replace(/'/g, '"');
    variant_json = JSON.parse(variant_json || '{}');
    const lock_type_name = $('#lock-type-selector option:selected').text().toLocaleLowerCase();
    let price = 0;

    variant_json.forEach(variant => {
        if (variant.name.toLocaleLowerCase() === lock_type_name) {
            price = variant.price_brutto;
        }
    });

    const is_with_wc_lock = $('#lock-type-selector').find('option:selected').val() === 'WC';
    const wc_lock_price = is_with_wc_lock ? getLockPrice() : 0;

    product_price.latch = is_with_latch ? price : 0;
    product_price.lock = is_with_wc_lock ? wc_lock_price * 1.27 : 0;

    $('#latch-price').html(
        setThousand(Math.round(price + wc_lock_price * 1.27)) + ' Ft (brutt&oacute;)'
    );

    updateProductPriceTotal();
}

function setBasektButtonUrl() {
    const door_id = $('#data_product_id').data('id');
    const datablock = $('#datablock');
    const is_with_latch = $('#is-with-latch').is(':checked');
    
    const door_description = 'Dekor ajtolap ' +
        datablock.data('product-color') + ', ' +
        // $('#wall-weight-selector').val() + ', ' +
        $('#sizes-selector').val() + ', ' +
        $('#open-direction-selector').val() + ', ' +
        (is_with_latch ? $('#lock-type-selector').val() + ' zar, ' : '') +
        'krom pant';

    const is_with_frame = $('#is-with-frame').is(':checked');
    const frame_id = datablock.data('frame-id');
    const frame_description = 'Dekor ajto tok ' +
        datablock.data('product-color') + ', ' +
        $('#wall-weight-selector').val() + ', ' +
        $('#sizes-selector').val() + ', ' +
        $('#open-direction-selector').val();
    const frame_price = getFramePrice();

    const is_with_wc_lock = $('#lock-type-selector').find('option:selected').val() === 'WC';
    const wc_lock_id = datablock.data('lock-type-id');
    const wc_lock_description = 'WC zar, ' + $('#open-direction-selector').val();
    const wc_lock_price = getLockPrice();
    
    const latch_id = $('#latch-selector').val();
    const latch_description = 'Tipus: ' + $('#lock-type-selector').val();
    
    let url = [
        WEBURL + 'index.cgi?act=kosarba_rakom',
        ...urlString(door_id, door_description),
        ...(is_with_frame ? urlString(frame_id, frame_description, frame_price) : '1=1'),
        ...(is_with_latch ? urlString(latch_id, latch_description, product_price.latch / 1.27) : '1=1'),
        ...(is_with_wc_lock ? urlString(wc_lock_id, wc_lock_description, wc_lock_price / 1.27) : '1=1'),
        (is_with_wc_lock ? 'lock_type=' + $('#lock-type-selector').val() : '1=1'),
    ];

    $('.basket-button').attr('href', url.join('&'));
}

function getLockPrice() {
    const datablock = $('#datablock');
    const frameVariants = JSON.parse(datablock.data('lock-type-variants').replace(/'/g, '"'));
    const direction = $('#open-direction-selector').val();
    let price = 0;

    frameVariants.forEach(variant => {
        if (variant.name === direction) {
            price = variant.price_netto;
        }
    });

    return price;

}

function getFramePrice() {
    const datablock = $('#datablock');
    const frameVariants = JSON.parse(datablock.data('frame-variants').replace(/'/g, '"'));
    const frame_size = $('#wall-weight-selector').val();
    const direction = $('#open-direction-selector').val();
    const is_with_frame = $('#is-with-frame').is(':checked');
    let price = 0;

    frameVariants.forEach(variant => {
        if (variant.name === frame_size + ' ' + direction) {
            price = variant.price_netto;
        }
    });

    $('.frame-price').html(setThousand(Math.round(price * 1.27)) + ' Ft (brutt&oacute;)');

    product_price.frame = is_with_frame ? price * 1.27 : 0;

    return price;
}

function urlString(product_id, description, ar) {
    if (!product_id) {
        return [];
    }

    const mennyiseg = $('#mennyiseg').val();

    const items = [
        'termek_id_' + product_id + '=' + product_id,
        'mennyiseg_' + product_id + '=' + mennyiseg,
        'description_' + product_id + '=' + encodeURI((description).normalize("NFD").replace(/[\u0300-\u036f]/g, "")),
    ];

    if (!!ar) {
        items.push('ar_' + product_id + '=' + ar);
    }

    return items;
}
